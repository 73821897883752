import { Text, Title } from "@mantine/core";
import { TbFaceIdError } from "react-icons/tb";
import AppLayout from "~/layouts/_app";

export default function NotFound() {
  return (
    <AppLayout withPadding={true}>
      <div className="flex flex-col items-center justify-center h-full">
        <TbFaceIdError
          size={120}
          className="mb-6"
          style={{ color: "#9ca3af", marginTop: "3rem" }}
        />
        <Title order={1} className="text-3xl font-bold mb-2">
          404 - Page Not Found
        </Title>
        <Text size="lg" className="mb-6 mt-2 text-gray-500">
          Oops! The page you&apos;re looking for doesn&apos;t exist.
        </Text>
        <button
          onClick={() => window.history.back()}
          className="px-4 py-2 bg-secondary-700 text-white rounded hover:bg-secondary/80 transition-colors"
        >
          Go Back
        </button>
      </div>
    </AppLayout>
  );
}
